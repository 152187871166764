import { Injectable } from '@angular/core';
import { FacultyI } from './interfaces/faculty.interface';
import { FilterFacultiesI } from './interfaces/filter-faculties.interface';
import { AddFacultyRequestBodyI } from './interfaces/add-faculty-request-body.interface';
import { EditFacultyRequestBodyI } from './interfaces/edit-faculty-request-body.interface';
import { ApiService } from '../../core/services/api/api.service';
import { DeleteFacultyRequestQuery } from './interfaces/delete-faculty-request-query.interface';
import { FacultiesCardI } from './interfaces/faculties-card.interface';

@Injectable({
  providedIn: 'root',
})
export class FacultiesApiService {
  constructor(private readonly apiService: ApiService) {}

  findAll(filterFaculties: FilterFacultiesI) {
    return this.apiService.get<FacultyI[]>('faculties', filterFaculties);
  }

  findOne(facultyID: string) {
    return this.apiService.get<FacultyI>(`faculties/${facultyID}`);
  }

  editFaculty(
    facultyID: string,
    editFacultyRequestBody: EditFacultyRequestBodyI
  ) {
    return this.apiService.patch<FacultyI>(
      `faculties/${facultyID}`,
      editFacultyRequestBody
    );
  }

  addFaculty(addFacultyRequestBody: AddFacultyRequestBodyI) {
    return this.apiService.post<FacultyI>(`faculties`, addFacultyRequestBody);
  }

  deleteFaculty(
    facultyID: string,
    deleteFacultyRequestQuery: DeleteFacultyRequestQuery
  ) {
    return this.apiService.delete<FacultyI>(
      `faculties/${facultyID}`,
      deleteFacultyRequestQuery
    );
  }

  card() {
    return this.apiService.get<FacultiesCardI>(`faculties/card`);
  }
}
